o<template>
  <div>
    <a-row :gutter="10">
      <a-col span="12">
        <a-card title="基本信息" :bodyStyle="{'height': '600px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editTask']">保存</a-button>
          </div>
          <div>
            <a-form-model
                ref="infoForm"
                labelAlign="right"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }"
                :model="detail"
                :rules="infoRules"
                :hideRequiredMark="true"
            >
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="任务名称" prop="taskName">
                    <a-input placeholder="请输入任务名称" v-model="detail.taskName" :disabled="!canModify" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="计划开始日期" prop="planStartDate">
                    <a-date-picker
                        :value="detail.planStartDate ? moment(detail.planStartDate) : null"
                        @change="(date, dateStr) => dateChange(date, dateStr, 'planStartDate')"
                        style="width: 100%"
                        :disabled="!canModify"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="任务状态" prop="taskStatus">
                    <a-select v-model="detail.taskStatus" placeholder="请选择任务状态" :disabled="!canModify">
                      <a-select-option :value="0">未开始</a-select-option>
                      <a-select-option :value="1">审批中</a-select-option>
                      <a-select-option :value="2">进行中</a-select-option>
                      <a-select-option :value="3">已完成</a-select-option>
                      <a-select-option :value="4">已搁置</a-select-option>
                      <a-select-option :value="5">已取消</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="计划结束日期" prop="planEndDate">
                    <a-date-picker
                        :value="detail.planEndDate ? moment(detail.planEndDate) : null"
                        @change="(date, dateStr) => dateChange(date, dateStr, 'planEndDate')"
                        style="width: 100%"
                        :disabled="!canModify"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="任务类型" prop="taskCategoryId">
                    <a-select v-model="detail.taskCategoryId" placeholder="请选择任务类型" :disabled="!canModify">
                      <a-select-option :value="item.id" v-for="(item, index) in taskTypeList" :key="item.id">
                        {{ item.categoryName }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="实际开始日期" prop="startDate">
                    <a-date-picker
                        :value="detail.startDate ? moment(detail.startDate) : null"
                        @change="(date, dateStr) => dateChange(date, dateStr, 'startDate')"
                        style="width: 100%"
                        :disabled="!canModify"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="任务等级" prop="taskLevel">
                    <a-select v-model="detail.taskLevel" placeholder="请选择任务等级" :disabled="!canModify">
                      <a-select-option :value="1">重要</a-select-option>
                      <a-select-option :value="2">普通</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="实际结束日期" prop="endDate">
                    <a-date-picker
                        :value="detail.endDate ? moment(detail.endDate) : null"
                        @change="(date, dateStr) => dateChange(date, dateStr, 'endDate')"
                        style="width: 100%"
                        :disabled="!canModify"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="任务负责人" prop="taskLeaderId">
                    <a-select v-model="detail.taskLeaderId" placeholder="请选择任务负责人" :disabled="!canModify">
                      <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="任务进度">
                    <a-row type="flex" :gutter="10">
                      <a-col flex="auto">
                        <a-progress :percent="detail.taskProgressValue * 100 || 0" />
                      </a-col>
                      <a-col flex="80px" v-if="canModify">
                        <a-select v-model="detail.taskProgressValue" placeholder="请选择任务进度">
                          <a-select-option :value="count/10" v-for="count in 10" :key="count">{{ count * 10 }}%</a-select-option>
                        </a-select>
                      </a-col>
                    </a-row>


                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="关联项目" prop="projectId">
                    <a-select v-model="detail.projectId" placeholder="请选择关联项目" :disabled="!canModify" @change="projectChange">
                      <a-select-option :value="item.id" v-for="(item, index) in projectList" :key="item.id">{{item.projectName }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="审批点" prop="expenditureType">
                    <span v-if="detail.expenditureType === 0">无</span>
                    <span v-if="detail.expenditureType === 1">任务申请进行</span>
                    <span v-if="detail.expenditureType === 2">任务申请完成</span>
                    <span v-if="detail.expenditureType === 3">任务申请取消</span>
                    <span v-if="detail.expenditureType === 4">任务申请搁置</span>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row class="margin-t10" :gutter="20">
                <a-col span="12" style="padding-left: 30px">
                  <a-form-model-item prop="projectId" :wrapperCol="{ span: 24 }">
                    <div class="">关键指标</div>
                    <a-textarea placeholder="请输入关键指标" v-model="detail.majorIndicator" style="height: 200px" :disabled="!canModify" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item prop="remark" :wrapperCol="{ span: 24 }">
                    <div class="">任务描述</div>
                    <a-textarea placeholder="请输入任务描述" v-model="detail.remark" style="height: 200px" :disabled="!canModify" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col span="4">
        <a-card title="任务成员"  :bodyStyle="{'height': '600px'}">
          <a-row style="height: calc(100% - 200px); overflow-y: auto">
            <a-row class="padding-tb10" v-for="(item, index) in taskMemberList" :key="item.id">
              <a-col :span="10" class="font-c9">{{ item.taskPosition }}：</a-col>
              <a-col :span="10">{{ item.staffName }}</a-col>
              <a-col :span="4">
                <a-popconfirm title="确定删除?" @confirm="() => delMember(item)">
                <img src="@/assets/image/common/del.png" alt="" class="cursor" v-has-permission="['editTask']">
                </a-popconfirm>
              </a-col>
            </a-row>
          </a-row>
          <a-row class="margin-t20" v-if="canModify">
            <a-form-model
                ref="memberForm"
                labelAlign="right"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                :model="members"
                :rules="memberRules"
                :hideRequiredMark="true"
            >
              <a-form-model-item label="任务职位" prop="positionId">
                <a-select v-model="members.positionId" placeholder="请选择项目职位">
                  <a-select-option :value="item.id" v-for="(item) in jobList" :key="item.id">{{ item.positionName }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="员工" prop="staffName">
                <div @click="openStaff">
                  <a-input placeholder="请选择员工" v-model="members.staffName" disabled></a-input>
                </div>
              </a-form-model-item>
              <a-row style="margin-top: 20px">
                <a-form-model-item :wrapper-col="{ span: 20, offset: 2 }">
                  <a-button type="primary" block @click="addMember">确认添加</a-button>
                </a-form-model-item>
              </a-row>
            </a-form-model>
          </a-row>
        </a-card>
      </a-col>
      <a-col span="8">
        <a-card title="任务依赖关系" :bodyStyle="{'height': '600px'}">
          <div class="display-f justify-center padding-t15">
            <a-radio-group v-model="dependencyForm.relationType" @change="getDependencyList">
              <a-radio-button :value="1"><div class="width-150 text-c">前置（{{ dependencyCount1 }}）</div></a-radio-button>
              <a-radio-button :value="2"><div class="width-150 text-c">后置（{{ dependencyCount2 }}）</div></a-radio-button>
            </a-radio-group>
          </div>
          <div class="margin-t30 margin-lr40 padding-lr40" style="height: calc(100% - 280px)">
            <template  v-for="(item, index) in dependencyList">
              <div class="display-f justify-between align-items-c" :key="item.id">
                <span v-if="item.dependencyType === 1">SS</span>
                <span v-if="item.dependencyType === 2">FS</span>
                <span v-if="item.dependencyType === 3">SF</span>
                <span v-if="item.dependencyType === 4">FF</span>
                <span class="flex1 margin-l10">{{ item.taskName }}</span>
                <img src="@/assets/image/common/link.png" alt="" class="cursor" @click="delDependency(item)" v-has-permission="['editTask']">
              </div>
            </template>
          </div>
          <a-row class="margin-t20" v-if="canModify">
            <a-form-model
                ref="dependencyForm"
                labelAlign="right"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 18 }"
                :model="dependencyForm"
                :rules="dependencyRules"
                :hideRequiredMark="true"
            >
              <a-form-model-item label="任务名称" prop="relatedTaskId">
                <a-select v-model="dependencyForm.relatedTaskId" placeholder="请选择任务">
                  <a-select-option :value="item.id" v-for="(item) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="依赖关系" prop="dependencyType">
                <a-select v-model="dependencyForm.dependencyType" placeholder="请选择依赖关系">
                  <a-select-option :value="1">SS</a-select-option>
                  <a-select-option :value="2">FS</a-select-option>
                  <a-select-option :value="3">SF</a-select-option>
                  <a-select-option :value="4">FF</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-row style="margin-top: 20px">
                <a-form-model-item :wrapper-col="{ span: 20, offset: 2 }">
                  <a-button type="primary" block @click="addDependency">确认添加</a-button>
                </a-form-model-item>
              </a-row>
            </a-form-model>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="5">
        <a-card title="数据指标" :bodyStyle="{'height': '800px'}">
          <div>
            <a-form-model
                labelAlign="right"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
            >
              <a-row v-if="detail.projectTaskDataDTO">
                <a-form-model-item label="任务进度">
                  <a-progress :percent="detail.projectTaskDataDTO.taskProgressValue || 0" />
                </a-form-model-item>
                <a-form-model-item label="持续时间(天)">
<!--                  <span>计划{{ detail.projectTaskDataDTO.planDurationDays }}</span>-->
<!--                  <span>/实际{{ detail.projectTaskDataDTO.actualDurationDays }}</span>-->
                  <span>{{ detail.projectTaskDataDTO.durationDays }}</span>
                </a-form-model-item>
                <a-form-model-item label="处理问题">
                  <a-progress :percent="detail.projectTaskDataDTO.finishedProblemNum / detail.projectTaskDataDTO.totalProblemNum * 100"
                              :format="() => `${(detail.projectTaskDataDTO.finishedProblemNum || 0) + '/' + (detail.projectTaskDataDTO.totalProblemNum || 0)}`"
                  />
                </a-form-model-item>
                <a-form-model-item label="预算支出">
                  <a-progress :percent="detail.projectTaskDataDTO.planExpenditure || 0" />
                </a-form-model-item>
                <a-form-model-item :label="item.expenditureCategoryName" v-for="(item, index) in detail.projectTaskDataDTO.taskExpenditureDatas" :key="index">
                  <a-progress :percent="item.expenditurePercentage || 0" />
                </a-form-model-item>
<!--                <a-form-model-item :label="item.expenditureCategoryName" v-for="(item, index) in detail.projectTaskDataDTO.actualExpenditureDatas" :key="index">-->
<!--                  <a-progress :percent="item.expenditurePercentage * 1000 / 10 || 0" />-->
<!--                </a-form-model-item>-->
              </a-row>
              <a-row class="margin-t20">
                <div class="">实际支出占比</div>
                <div class="height-200" id="pieChart"></div>
              </a-row>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col span="19">
        <EditTable
            :type="1"
            :taskId="id"
            :value="detail.planTaskExpenditure"
            v-if="detail.planTaskExpenditure && detail.planTaskExpenditure.expenditureVOList"
            :canModify="canModify"
            @change="getDetail"
        >
        </EditTable>
        <EditTable
            :type="2"
            :taskId="id"
            :value="detail.actualTaskExpenditure"
            v-if="detail.actualTaskExpenditure && detail.actualTaskExpenditure.expenditureVOList"
            :canModify="canModify"
            style="margin-top: 15px"
            @change="getDetail"
        >
        </EditTable>
      </a-col>
    </a-row>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="5">
        <a-card title="关联文件">
          <div slot="extra">
            <span class="dominant" v-has-permission="['editTask']">一键下载</span>
          </div>
          <div class="line-h30">
            <div class="display-f justify-between align-items-c" v-for="(item, index) in taskFileList" :key="item.id">
              <span>{{ item.fileName }}</span>
              <div>
                <img src="@/assets/image/common/download.png" alt="" @click="openFiles(item)" v-if="item.fileUrl">
                <img src="@/assets/image/common/edit.png" alt="" class="margin-l20" @click="openFile('edit', item)">
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col span="19">
        <a-card title="任务日志">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
    <SelectStaff
        :visible="staffShow"
        :type="1"
        :value="stakeholder.customerId"
        @submit="staffChange"
        @close="staffShow = false"
        v-if="staffShow"
    ></SelectStaff>
    <a-drawer
        :title="`${fileForm.id ? '编辑' : '新增'}文件`"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="700px"
        class="drawer"
        v-if="createShow"
    >
      <a-form-model
          ref="fileForm"
          labelAlign="right"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          :model="fileForm"
          :rules="fileRules"
      >
        <a-row>
          <a-form-model-item label="文件名称" prop="fileName">
            <a-input placeholder="请输入文件名称" v-model="fileForm.fileName" />
          </a-form-model-item>
          <a-form-model-item label="文件类型" prop="fileType">
            <a-radio-group name="radioGroup" :default-value="1" v-model="fileForm.fileType">
              <a-radio :value="1">上传</a-radio>
              <a-radio :value="2">共享</a-radio>
              <a-radio :value="3">云文件</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <template v-if="fileForm.fileType === 1">
            <a-form-model-item label="上传文件" prop="fileUrl" :required="true">
              <a-upload-dragger
                  name="file"
                  :multiple="false"
                  action="http://118.190.215.184:8383/file/uploadFile"
                  :headers="headers"
                  :file-list="fileList"
                  accept=".jpg,.jpeg,.gif,.png,.pdf,.doc,.xls,.dat,.txt,.glb"
                  @change="fileChange"
                  :class="{'drag-uploader' : fileList.length}"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="cloud-upload" />
                </p>
                <p class="ant-upload-text">
                  多个文件请先存入压缩包
                </p>
              </a-upload-dragger>
            </a-form-model-item>
          </template>
          <template v-if="fileForm.fileType === 2">
            <a-form-model-item label="引用共享文件" prop="sharedFileId">
              <a-select v-model="fileForm.sharedFileId" placeholder="请引用共享文件">
                <a-select-option :value="item.id" v-for="(item, index) in shareFileList" :key="item.id">{{ item.fileName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </template>
          <a-form-model-item label="关联任务" prop="projectTaskIds">
            <a-select mode="multiple" v-model="fileForm.projectTaskIds" placeholder="请选择关联任务">
              <a-select-option :value="item.id" v-for="(item, index) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关联任务成员" prop="taskMemberIds">
            <a-select mode="multiple" v-model="fileForm.taskMemberIds" placeholder="请选择关联任务成员">
              <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{ item.staffName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="关联产品" prop="productId">
            <a-select v-model="fileForm.productId" placeholder="请选择关联产品">
              <a-select-option :value="item.id" v-for="(item, index) in productList" :key="item.id">{{ item.productName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="浏览下载权限" prop="productId">
            <a-select mode="multiple" v-model="fileForm.postIds" placeholder="请选择浏览下载权限">
              <a-select-option :value="item.id" v-for="(item, index) in fileJobList" :key="item.id">{{ item.postName }}（{{ item.deptName}}）</a-select-option>
            </a-select>
          </a-form-model-item>
          <template v-if="fileForm.fileType === 3">
            <a-form-model-item label="云文件内容" prop="messageContent">
              <QuillEditor v-model="fileForm.messageContent"></QuillEditor>
            </a-form-model-item>
          </template>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
            <a-button type="primary" @click="fileSubmit">确定</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
import SelectStaff from '@/components/select/selectStaff'
import EditTable from '@/pages/task/taskDetail/editTable'
import { getProjectList } from '../../../api/project/project'
import moment from 'moment';
import { getJobList } from '../../../api/humanResources/job'
import { createTask, getTaskMember, getTaskTypeList, getTaskList, getProjectMember } from '../../../api/task/task'
import {
  getTaskDetail,
  deleteMember,
  createMember,
  getTaskFileList,
  createDependency, getDependencyList, deleteDependency
} from '../../../api/task/taskDetail'
import storage from 'store'
import QuillEditor from '@/components/Editor/QuillEditor'
import { mapState } from 'vuex'
import { getTaskPositionList } from '../../../api/system/settings'
import { getFileList } from '@/api/project/file'
import { getProductList } from '@/api/product/product'
import { createProjectFile } from '@/api/project/projectDetail'
import { getStaffList } from '../../../api/humanResources/staff'


export default {
  name: 'overview',
  components: {
    SelectStaff,
    EditTable,
    QuillEditor
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      detail: {
        projectTaskDataDTO: {
          taskExpenditureDatas: {},
          actualExpenditureDatas: []
        }
      },
      canModify: false,
      infoRules: {
        taskName: [
          { required: true, message: '请输入任务名称', trigger: 'blur' }
        ],
        taskStatus: [
          { required: true, message: '请选择任务状态', trigger: 'change' }
        ],
        taskCategoryId: [
          { required: true, message: '请选择任务类型', trigger: 'change' }
        ],
        taskLevel: [
          { required: true, message: '请选择任务等级', trigger: 'change' }
        ],
        projectId: [
          { required: true, message: '请选择关联项目', trigger: 'change' }
        ]
      },
      memberRules: {
        positionId: [
          { required: true, message: '请选择项目职位', trigger: 'change' }
        ],
        staffName: [
          { required: true, message: '请选择员工', trigger: 'change' }
        ]
      },
      stakeholderRules: {
        customerId: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        stakeholderLevel: [
          { required: true, message: '请选择重要程度', trigger: 'change' }
        ],
        stakeholderId: [
          { required: true, message: '请选择干系人', trigger: 'change' }
        ]
      },
      members: {},
      stakeholder: {},
      staffShow: false,
      jobList: [],
      fileJobList: [],
      memberList: [],
      taskMemberList: [],
      projectStakeholderList: [],
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
      taskTypeList: [],
      projectList: [],
      taskList: [],
      taskFileList: [],
      fileForm: {},
      fileRules: {
        fileName: [
          { required: true, message: '请输入文件名称', trigger: 'blur' }
        ],
        fileType: [
          { required: true, message: '请选择文件类型', trigger: 'change' }
        ],
        fileUrl: [
          { validator: this.validateFile, trigger: 'change' }
        ],
        sharedFileId: [
          { required: true, message: '请选择引用共享文件', trigger: 'change' }
        ]
      },
      headers: {
        'token': storage.get('Access-Token')
      },
      fileList: [],
      dependencyForm: {
        relationType: 1,
        taskId: this.id
      },
      dependencyRules: {
        relatedTaskId: [
          { required: true, message: '请选择任务', trigger: 'change' }
        ],
        dependencyType: [
          { required: true, message: '请选择依赖关系', trigger: 'change' }
        ],
      },
      createShow: false,
      dependencyList: [],
      dependencyCount1: 0,
      dependencyCount2: 0,
      shareFileList: [],
      productList: []
    }
  },
  mounted () {
    this.members.taskId = this.id
    // this.getMember()
    this.getDetail()
    this.getJob()
    this.getTaskMember()
    this.getTaskType()
    this.getProject()

    this.getTaskFile()
    this.getDependencyList()
    this.getAllDependencyList()
    this.getShareFileList()
    this.getProduct()
    this.getFileJob()
    this.canModify = this.authority.editTask
  },
  methods: {
    moment,
    getDetail () {
      getTaskDetail({
        id: this.id
      }).then(res => {
        this.detail = res.data
        if (!res.data.projectTaskDataDTO) {
          this.detail.projectTaskDataDTO = {
            taskExpenditureDatas: {},
            actualExpenditureDatas: []
          }
        }
        if (this.detail.projectId) {
          this.getMember()
        }
        this.drawChart()
        this.getTask()
      })
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createTask(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getDetail()
          })
        }
      })
    },
    staffChange (data) {
      this.members.staffId = data.id
      this.members.staffName = data.staffName
      this.$refs.memberForm.clearValidate('staffName', (res) => {console.log(res)})
      this.staffShow = false
    },
    getJob () {
      getTaskPositionList().then(res => {
        this.jobList = res.data.list
      })
    },
    openStaff () {
      if (this.canModify) {
        this.staffShow = true
      }
    },
    addMember () {
      this.$refs.memberForm.validate(valid => {
        if (valid) {
          createMember(this.members).then(() => {
            this.$message.success('添加成功！')
            this.getTaskMember()
          })
        }
      })
    },
    projectChange () {
      this.getMember()
    },
    getMember () {
      getProjectMember({
        projectId: this.detail.projectId
      }).then(res => {
        this.memberList = res.data
      })
      // getStaffList({
      //   pageNum: 1,
      //   pageSize: 10000
      // }).then(res => {
      //   this.memberList = res.data.list
      // })
    },
    getTaskMember () {
      getTaskMember({
        ids: [this.id]
      }).then(res => {
        this.taskMemberList = res.data
      })
    },
    delMember (item) {
      deleteMember({
        id: item.id
      }).then(() => {
        this.$message.success('删除成功！')
        this.getMember()
      })
    },
    dateChange (date, dateString, type) {
      this.detail[type] = dateString
    },
    getTaskType () {
      getTaskTypeList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskTypeList = res.data.list
      })
    },
    getProject () {
      getProjectList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.projectList = res.data.list
      })
    },
    getTask () {
      getTaskList({
        queryParam: {
          projectId: this.detail.projectId
        },
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskList = res.data.list
      })
    },
    getTaskFile () {
      getTaskFileList({
        id: this.id
      }).then(res => {
        this.taskFileList = res.data
      })
    },
    openFiles (item) {
      window.open('http://project.xteknology.com/upload/' + item.fileUrl)
    },
    validateFile (rule, value, callback) {
      if (this.fileForm.fileUrl) {
        callback()
      } else {
        callback(new Error('请上传文件'))
      }
    },
    fileChange (res) {
      if (res.file.response) {
        this.fileForm.fileUrl = res.file.response.data
      } else {
        this.fileForm.fileUrl = ''
      }
      this.fileList = res.fileList
    },
    openFile (type, item) {
      if (item) {
        this.fileForm = {  ...item }
        this.fileList = [{
          uid: item.id,
          name: item.fileName,
          status: item.done,
          url: 'http://project.xteknology.com/upload/' + item.fileUrl
        }]
      }
      this.createShow = true
    },
    addDependency () {
      createDependency(this.dependencyForm).then(() => {
        this.getDependencyList()
        this.getAllDependencyList()
        this.dependencyForm = {
          relationType: 1,
          taskId: this.id
        }
        this.$message.success('添加成功！')
      })
    },
    delDependency (item) {
      console.log(item)
      this.$confirm({
        title: '提示',
        content: '确认解除关联？',
        centered: true,
        onOk: () => {
          deleteDependency({
            relatedTaskId: item.id,
            relationType: this.dependencyForm.relationType
          }).then(() => {
            this.getDependencyList()
            this.$message.success('已解除关联！')
          })
        }
      })
    },
    closeCreate () {
      this.fileForm = {}
      this.createShow = false
    },
    getAllDependencyList () {
      this.$util.multipleRequest([
        {url: `/projectTask/listTaskByDependency?relationType=1&taskId=${this.id}`},
        {url: `/projectTask/listTaskByDependency?relationType=2&taskId=${this.id}`}
      ]).then(res => {
        this.dependencyCount1 = res[0].data.length
        this.dependencyCount2 = res[1].data.length
      })
    },
    getDependencyList () {
      getDependencyList({
        taskId: this.id,
        relationType: this.dependencyForm.relationType
      }).then(res => {
        this.dependencyList = res.data
      })
    },
    drawChart () {
      let arr = []
      if (!this.detail.projectTaskDataDTO || !this.detail.projectTaskDataDTO.actualExpenditureDatas) return
      this.detail.projectTaskDataDTO.actualExpenditureDatas.forEach(item => {
        arr.push({
          value: item.expenditurePercentage,
          name: item.expenditureCategoryName
        })
      })
      console.log(arr)
      const pieChart = this.$echarts.init(document.getElementById('pieChart'))
      const optionPie = {
        tooltip: {
          trigger: 'item',
          formatter: "{b}{d}%",
          confine: true
        },
        series: [
          {
            name: '实际支出占比',
            type: 'pie',
            data: arr,
            labelLine: {
              show: true
            },
            label: {
              show: true
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      pieChart.setOption(optionPie)
    },
    getShareFileList () {
      getFileList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.shareFileList = res.data.list
      })
    },
    getProduct () {
      getProductList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.productList = res.data.list
      })
    },
    getFileJob () {
      getJobList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.fileJobList = res.data.list
      })
    },
    onSubmit () {
      let item = {...this.fileForm}
      if (!(item.projectPositionIds && item.projectPositionIds.length)) item.projectPositionIds = null
      if (!(item.taskMemberIds && item.taskMemberIds.length)) item.taskMemberIds = null
      if (!(item.sharedFileId && item.sharedFileId.length)) item.sharedFileId = null
      this.$refs.fileForm.validate(valid => {
        if (valid) {
          createProjectFile(item).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
